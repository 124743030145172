import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import ClockIcon from "../../../../static/Clock.svg"

const FlashGbpStickyBannerWrapper = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  position: relative;
  background: ${props => props.theme.saleColor.bgColor};
  background: #2a5423;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  overflow: hidden;
  border-left: 0px;
  border-right: 0px;
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
  display: none;
  display: block;

  @media (max-width: 600px) {
    display: block;
  }
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Container = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 20px;

  @media (max-width: 450px) {
    padding: 8px 15px;
  }
`

const MobileButton = styled(Link)`
  display: none;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  margin-left: 10px;

  @media (max-width: 600px) {
    display: inline-block;
  }
`

const TextBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  text-align: left;

  @media (max-width: 600px) {
    // width: 100%;
  }
`

const LeftText = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 30px;
  line-height: 1;

  @media (max-width: 767px) {
    font-size: 21px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileLeftText = styled.div`
  display: none;
  position: relative;
  font-family: "Museo";
  font-weight: 900;
  font-size: 16px;
  line-height: 1;

  span {
    display: block;
  }

  @media (max-width: 600px) {
    display: block;
  }
`

const RightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Museo";
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  margin-left: 10px;

  > span {
    font-size: 30px;
    font-weight: 900;
    // background: ${props => props.theme.saleColor.textColor};
    // color: ${props => props.theme.saleColor.bgColor};
    // border: ${props => "2px solid" + props.theme.saleColor.borderColor};
    // padding: 7px 10px 6px;
    // border-radius: 15px 0px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 21px;
    }

    @media (max-width: 600px) {
      font-size: 30px;
    }

    @media (max-width: 350px) {
      font-size: 24px;
    }
  }

  > div {
    margin-left: 10px;

    @media (max-width: 480px) {
      max-width: 55px;
    }
  }

  @media (max-width: 600px) {
    // font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`

const Button = styled(Link)`
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  font-size: 16px;
  font-family: "Museo";
  font-weight: 700;
  margin-left: 35px;
  text-decoration: underline;
  line-height: 22px;
  display: inline-block;

  @media (max-width: 600px) {
    display: none;
  }

  :hover {
    color: ${props => props.theme.saleColor.textColor};
    color: #fff;
  }
`

const CloseButton = styled.div`
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
  font-size: 20px;
  padding: 0px;
  box-shadow: none;
  position: absolute;
  line-height: 10px;
  font-weight: normal;
  right: 5px;
  top: 5px;
  margin: 0px;
  cursor: pointer;
  z-index: 1;

  :hover {
    color: ${props => props.theme.saleColor.textColor};
    color: #fff;
  }
`

const ClockImage = styled.img`
  width: 100%;
  max-width: 35px;
  min-width: 35px;
  margin: 0px 7px 0px 0px;
`

const FlashLeftText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Gotham Book";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;
`

const FlashRightText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Gotham Book";
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: ${props => props.theme.saleColor.textColor};
  color: #fff;

  > span:nth-of-type(1) {
    margin-right: 7px;

    @media (max-width: 600px) {
      margin-right: 0px;
    }
  }

  > span:nth-of-type(2) strong {
    color: #fedb81;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 360px) {
    font-size: 14px;
  }
`

const FlashGbpStickyBanner = ({ intl }) => {
  const [LyneHomeStickyHide, setLyneHomeStickyHide] = useState(false)
  const [activeFlag, setactiveFlag] = useState(true)

  let currentPath = ""
  let ButtonHide = false
  let isShopPage = false

  let regex = /(lyneup|lynefit|lynehome|shop|cart|panier)/
  if (isBrowser) {
    currentPath = window.location.pathname

    let pathArr = currentPath.split("/")

    if (
      pathArr[pathArr.length - 1] === "shop" ||
      pathArr[pathArr.length - 2] === "shop"
    ) {
      isShopPage = true
    }
    ButtonHide = regex.test(currentPath)
  }

  const hideBanner = () => {
    isBrowser && sessionStorage.setItem("MaiStickyBanner", true)
    setLyneHomeStickyHide(true)
  }
  const checkVisibilityBanner = () => {
    if (isBrowser) {
      sessionStorage.getItem("MaiStickyBanner") && setLyneHomeStickyHide(true)
    }
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }
    $(window).resize(() => {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })

    $(document).ready(function() {
      var w = window.outerWidth
      if (w > 600) {
        if (isOnScreen($(".HomeVideoBanner"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(false)
        } else {
          setactiveFlag(true)
        }
        window.addEventListener("scroll", function(e) {
          if (isOnScreen($(".HomeVideoBanner"))) {
            /* Pass element id/class you want to check */
            setactiveFlag(false)
          } else {
            setactiveFlag(true)
          }
        })
      }
    })
  }

  useEffect(() => {
    checkVisibilityBanner()
    // BottomBarActive()
  }, [intl.locale])
  return (
    !LyneHomeStickyHide &&
    activeFlag && (
      <FlashGbpStickyBannerWrapper className="LyneHomeStickyBanner">
        {ButtonHide ? (
          <Container>
            <TextBox>
              <FlashLeftText>
                <ClockImage className="lazyload" data-src={ClockIcon} />
              </FlashLeftText>
              <FlashRightText>
                <span>
                  <strong>Extra 10% OFF</strong> at checkout
                </span>
                <span>
                  until 02/04 with code <strong>FLASH</strong>
                </span>
              </FlashRightText>
            </TextBox>
          </Container>
        ) : (
          <Container>
            <LinkBox to="/shop/lyneup-test/">
              <LinkButtonTitle>{"Let's go >"}</LinkButtonTitle>
            </LinkBox>
            <TextBox>
              <LeftText>SUMMER SALE</LeftText>
              <MobileLeftText>SUMMER SALE</MobileLeftText>
              <RightText>
                <span>40% OFF</span>
                <div>
                  2<sup>nd</sup> top
                </div>
              </RightText>
            </TextBox>
            {!ButtonHide && (
              <Button to="/shop/lyneup-test/">{"Let's go >"}</Button>
            )}
            {!ButtonHide && (
              <MobileButton to="/shop/lyneup-test/">{">"}</MobileButton>
            )}
          </Container>
        )}
        <CloseButton onClick={() => hideBanner()}>×</CloseButton>
      </FlashGbpStickyBannerWrapper>
    )
  )
}

export default injectIntl(FlashGbpStickyBanner)
